import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import Invitation from './Invitation';
import QueryLoader from '../../../common/QueryLoader';
import { UserConsumer } from '../../../common/Auth';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: .5rem;
`;

export interface IInvitation {
  id: Number;
  email: String;
  role: String;
  modifiedAt: Date;
}

export interface IInvitationListResponse {
  users: IInvitation[]
}

export const ALL_INVITATIONS_LIST = gql`
    query ALL_INVITATIONS_LIST{
        invitations {
            id
            email
            token
            
        }
    }
`;

export const InvitationList = () => (
  <UserConsumer>
    {(me: UserMe) => (me.role === 'ADMIN' &&
      <QueryLoader query={ALL_INVITATIONS_LIST}>
        {({invitations}): IInvitationListResponse => (
          <List>
            {invitations && invitations.map(inv => <Invitation key={inv.id} invitation={inv} />)}
          </List>
        )}
      </QueryLoader>
    )}
  </UserConsumer>
);

export default InvitationList;
