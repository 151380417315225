import React, { Component } from 'react';

import {Route,Switch } from "react-router-dom";


import AppManagement from '../components/views/AppManagement/AppManagement';
import AppForm from '../components/views/AppManagement/AppForm';


class AppsIndex extends Component {
  render() {
    return (
      <Switch>
        <Route path="/apps/add" component={AppForm} />
        <Route component={AppManagement} />
      </Switch>
    );
  }
}

export default AppsIndex;
