import React, { Component } from 'react';

import {Route,Switch } from "react-router-dom";


import GraphQLDoc from '../components/views/Docs/GraphQL/GQLPlayground';
import RestDoc from '../components/views/Docs/Rest/RestDoc';


class DocsIndex extends Component {
  render() {
    return (
      <Switch>
        <Route path="/docs/graphql" component={GraphQLDoc} />
        <Route path="/docs/rest" component={RestDoc} />
      </Switch>
    );
  }
}

export default DocsIndex;
