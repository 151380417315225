import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ErrorMessage from '../../common/ErrorMessage';
import { Mutation } from 'react-apollo';
import QueryLoader from '../../common/QueryLoader';
import TopBar from '../../common/TopBar';
import PublicPage from '../../layouts/PublicPage';
import { withRouter } from 'react-router-dom';

const Form = styled.form`
   max-width: 500px;
   margin: 0 auto;
   .right{
      text-align: right;
      input{
      width: 8em;
      }
   }
`;

const GET_RESET_REQUEST = gql`
    query GET_RESET_REQUEST($token: String!){
        resetPasswordRequest(token: $token){
            user {
                email
            }
        }
    }
`;

const RESET_PASSWORD = gql`
    mutation RESET_PASSWORD($password: String!, $token: String!){
        resetPassword(body: {
            password: $password,
            token: $token
        }){
            id
        }
    }
`;

class PasswordResetForm extends Component {
  state = {
    password: '',
  };
  change = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  submit = (e, resetPassword) => {
    e.preventDefault();
    this.setState({
      token: this.props.query.token
    }, async () => {
      await resetPassword();
      this.props.history.push('/#Password changed successful! Please login.');
    });
  };
  render() {
    const {request} = this.props;
    return (
      <Mutation mutation={RESET_PASSWORD} variables={this.state}>
      {(resetPassword, {loading, error}) => (
        <Form method="POST" onSubmit={e => this.submit(e, resetPassword)}>
          <h2>Reset password</h2>
          <ErrorMessage error={error /* && {message: "Invalid username or password!"}*/} />
          <fieldset disabled={loading}>
            <input type="hidden" name="token" value={this.props.query.token}/>
            <label htmlFor="email">
              Email
              <input type="text" name='email' value={request.user.email} disabled/>
            </label>
            <label htmlFor="password">
              New password
              <input type="password" name='password' value={this.state.password} onChange={this.change} />
            </label>
            <div className='right'>
              <input className="button" type="submit" value='Save' />
            </div>
          </fieldset>
        </Form>
      )}
      </Mutation>
    );
  }
}


class PasswordResetPage extends Component {
  render(){
    return (
      <PublicPage>
        <QueryLoader query={GET_RESET_REQUEST} suppressError={true} variables={{token: this.props.match.params.token}}>
          {({resetPasswordRequest}, loading, error) => {
            if(error) return (<h3>Token have expired!</h3>);
            if(loading) return null;
            return (<PasswordResetForm request={resetPasswordRequest} query={this.props.match.params}/>);
          }}
        </QueryLoader>
      </PublicPage>
    );
  }
}


export default withRouter(PasswordResetPage);
