import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import User from './User';
import QueryLoader from '../../../common/QueryLoader';
import { UserConsumer } from '../../../common/Auth';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: .5rem;
`;

export interface IUser {
  id: Number;
  name: String;
  email: String;
  role: String;
  disabled: Boolean;
}

export interface IUserListResponse {
  users: IUser[]
}

export const ALL_USERS_LIST = gql`
    query ALL_USERS_LIST{
        users {
            id
            name
            email
            role
            disabled
        }
    }
`;

export const UserList = () => (
  <QueryLoader query={ALL_USERS_LIST}>
    {({users}): IUserListResponse => (
      <UserConsumer>
        {(me: UserMe) => (
          <List>
            {users && users.filter(user=>user.id !== me.id).map(user => <User key={user.id} user={user} />)}
          </List>
        )}
      </UserConsumer>

    )}
  </QueryLoader>
);

export default UserList;
