import React from 'react';
import { Container, Controls, Header, Title } from '../../styles/ListStyles';
import PrivatePage from '../../layouts/PrivatePage';
import { UserConsumer } from '../../common/Auth';
import type { IUser } from './details/UserList';


const Index = () => (
  <PrivatePage permission="USER">
    <UserConsumer>
      {(me: IUser) => (
        <Container>
          <Header>
            <Title>User profile</Title>
          </Header>
          <h4>{me.name}</h4>
          <div>{me.email}</div>
          <div>{me.role}</div>
        </Container>
      )}
    </UserConsumer>
  </PrivatePage>
);

export default Index;
