import React, { Component } from 'react';
import { Protected, UserConsumer } from '../common/Auth';
import TopBar from '../common/TopBar';
import { Link } from 'react-router-dom'
import type { IUser } from '../views/UserManagement/details/UserList';

class PrivatePage extends Component {
  render() {
    const { children } = this.props;
    return (
      <Protected>
        <UserConsumer>
          {(me: IUser) => (<>
          <TopBar>
            <div className="top-bar-right">
              <ul className="dropdown menu" data-dropdown-menu>
                <li><Link to='/dashboard'>Dashboard</Link></li>
                <li>
                  <a href="#">Docs</a>
                  <ul className="menu vertical">
                    <li><Link to='/docs/graphql'>GraphQL</Link></li>
                    <li><Link to='/docs/rest'>REST</Link></li>
                  </ul>
                </li>
                {me.role === 'ADMIN' && <li><Link to='/users'>Users</Link></li>}
                <li><Link to='/apps'>Apps</Link></li>
                <li className="menu-text">{me.name}
                  <ul className="menu vertical">
                    <li><Link to='/users/me'>Profile</Link></li>
                    <li><Link to='/logout'>Logout</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
          </TopBar>
          {
            typeof children === 'function'
              ? children(me)
              : children
          }
        </>)}
        </UserConsumer>
      </Protected>
    );
  }
}

export default PrivatePage;
