


import styled from 'styled-components';
import React from 'react';


const TopBar = styled.div`
  {
    font-size: 1.2em;
    background-color: ${props => props.theme.brand1};
    background: linear-gradient(45deg, ${props => props.theme.brand2} 40%, ${props => props.theme.brand1} 100%);
    color: white;
    margin-bottom: 1rem;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand {
    padding: 1rem 1rem;
    img {
      height: 40px;
    }
  }
  .top-bar-right{
    &>.menu{
      
      &>li{
      height: 4.5rem;
      padding: 2rem 1rem 1rem 1rem;
      vertical-align: middle;
      &>a{
      height: 100%;
      padding: 0;
      margin: 0;
      }
      }
    }
  }

  
  .dropdown{
    li{
      position: relative;
    }
    .menu {
      position: absolute;
      
      top: calc(100% - 1px);
      right: 0;
      z-index: 9;
      min-width: 100%;
      visibility: hidden;
      opacity:0;
      transition: opacity 0.3s;
    }
    li:hover{
      .menu{
        visibility: visible;
        opacity: 1;
        display: block;
        
      }
    }
  }
  
  .menu {
  height: 100%;
    background-color: transparent;
    //background-color: #1779ba;
    .vertical{
      height: auto;
      background-color: ${props=>props.theme.brand2};
      li {
      &:hover{
      background-color: ${props=>props.theme.brand1};
      }
       a {
        padding: 1rem;
        
       }
      }
    }
    
    color: white;
    li, a {
      color: white;
    }
    a:hover {
      //background-color: #0a3754
    }
  }

`;

export default ({children, ...props}) => (
  <TopBar className="top-bar">
    <div className="navbar-brand">
      <img src="/censusinsight.png" alt="Census Insight" className="m-1" />
    </div>
    {children}
  </TopBar>
);


