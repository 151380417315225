import React, {Component} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

const UserContainer = styled.div`
  position: relative;
  box-shadow: 1px 1px 3px 3px #e1e1e1;
  background-color: white;
  .info{
    padding: .5rem;
    .name{
      font-weight: 500;
    }
    .email{
      font-weight: 200;
      font-size: .9em;
    }
    .label{
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
  }
  .options {
    background-color: #1779ba;
    display: flex;
    flex-direction: row;
    .button {
      flex: 1 1 100%;
      margin-bottom: 0;
    }
  }
`;

const DISABLE_USER_MUTATION = gql`
  mutation DISABLE_USER_MUTATION($id: ID!, $disabled: Boolean!){
      disableUser(id: $id, disabled: $disabled){
          id
          disabled
      }
  }
`;

const REMOVE_USER_MUTATION = gql`
    mutation REMOVE_USER_MUTATION($id: ID!){
        deleteUser(id:$id){
            id
        }
    }
`;

const CHANGE_USER_MUTATION = gql`
    mutation CHANGE_USER_MUTATION($id: ID!, $role: Role!){
        changeUserRole(id: $id, role: $role){
            id
        }
    }
`;


export class User extends Component {
  render() {
    console.log(this.props);
    const {user: {id, name, email, role, disabled}} = this.props;
    return (
      <UserContainer>
        <div className="info">
          <div className='name'>{name}</div>
          <div className='email'>{email}</div>
          {role === 'ADMIN' && <div className='label alert'>ADMIN</div>}
          {disabled && <div className='label secondary'>disabled</div>}
        </div>
        <div className="options">
          <Mutation mutation={DISABLE_USER_MUTATION} variables={{id, disabled: !disabled}} refetchQueries={['ALL_USERS_LIST']}>
            {(disable, {loading, error}) => (
              <button disabled={loading} className="button" onClick={disable}>{disabled ? "Enable" : "Disable"}</button>
            )}
          </Mutation>
          <Mutation mutation={REMOVE_USER_MUTATION} variables={{id}} refetchQueries={['ALL_USERS_LIST']}>
            {(remove, {loading, error}) => (
              <button disabled={loading} className="button" onClick={remove}>{error || 'Remove'}</button>
            )}
          </Mutation>
          <Mutation mutation={CHANGE_USER_MUTATION} variables={{id, role: role === 'ADMIN' ? 'USER' : 'ADMIN'}} refetchQueries={['ALL_USERS_LIST']}>
            {(change, {loading, error}) => (
              <button disabled={loading} className="button" onClick={change}>{error && error.message} Make {role === 'ADMIN' ? 'USER' : 'ADMIN'}</button>
            )}
          </Mutation>
        </div>
      </UserContainer>
    );
  }
}

export default User;
