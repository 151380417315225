import React from 'react';
import styled from 'styled-components';

export const SuccessContainer = styled.div`
  background-color: lightgreen;
  color: black;
  border: 1px solid green;
  padding: .5em;
`;

export default ({message}) => (
  message ? (
    <SuccessContainer>
      {message}
    </SuccessContainer>
  ) : null
);

