import React, { Component } from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom'
import { ApolloConsumer } from 'react-apollo';

//language=SCSS
const Container = styled.div`{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${props=>props.theme.brand1}
}`;


//language=SCSS
const Message = styled.div`{
  margin: 0 auto;
  margin-top: 50%;
  color: white;
  a {
    color: #b2b5ff;
    &:hover {
      color: white;
      
    }
  }
  transform: translateY(-50%);
  text-align: center;
  word-break: break-all;
}`;

class Logout extends Component {
  componentDidMount(): void {
    localStorage.refresh_token = null;
    sessionStorage.access_token = null;
    setTimeout(()=>{
      this.props.history.push('/');
      return {}
    }, 3000);
  }

  render() {
    return (
      <ApolloConsumer>
        {client => {
          client.resetStore();
          return (
            <Container>
              <Message>
                <h2>See you later!</h2>
                <div><Link to="/">Go back to page</Link></div>
              </Message>
            </Container>
          );
        }}
      </ApolloConsumer>
    );
  }
}

export default withRouter(Logout);
