import React, {Component} from 'react';
import gql from 'graphql-tag';
import { Container, Controls, Header, Title } from '../../styles/ListStyles';
import {FormContainer} from '../../styles/FormStyles';
import MutationLoader from '../../common/MutationLoader';
import PrivatePage from '../../layouts/PrivatePage';
import { Link, withRouter } from 'react-router-dom';



const ADD_APP_MUTATION = gql`
    mutation ADD_APP_MUTATION($name: String!){
        createApp(
            name: $name
        ){
            id
        }
    }
`;

class AddApp extends Component {
  state = {
    name: ''
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value});
  };

  async onSubmit(e, addApp){
    e.preventDefault();
    await addApp();
    this.props.history.push('/apps');
  };

  render() {
    return (
      <PrivatePage permission="ADMIN">
        <Container>
          <Header>
            <Title>Apps</Title>
            <Controls>
              <Link to='/apps/add'>
                <button className='button'>Add</button>
              </Link>
            </Controls>
          </Header>
          <FormContainer>
            <Title>New app form</Title>

            <MutationLoader mutation={ADD_APP_MUTATION} variables={{name: this.state.name}} refetchQueries={['ALL_APPS_LIST']}>
              {(addApp) => (
                <form onSubmit={ev => this.onSubmit(ev, addApp)}>
                  <fieldset>
                    <label>
                      Name
                      <input type='text' value={this.state.name} onChange={this.handleChange} name='name' />
                    </label>
                    <input type="submit" value="Create" className="button" />
                  </fieldset>
                </form>
              )}
            </MutationLoader>
            <Link to='/apps'>go back</Link>
          </FormContainer>
        </Container>
      </PrivatePage>
    );
  }
}

export default withRouter(AddApp);
