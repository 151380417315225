import React, {Component} from 'react';

import styled from 'styled-components';

import ErrorMessage from '../../../components/common/ErrorMessage';
import { Mutation } from 'react-apollo';
import BusyWrapper from '../../../components/common/BusyWrapper';
import gql from 'graphql-tag';
import { Controls, Header, Title } from '../../../components/styles/ListStyles';
import { Link, withRouter } from 'react-router-dom';
import PrivatePage from '../../layouts/PrivatePage';

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const SEND_INVITATION = gql`
  mutation SEND_INVITATION($email: String!){
      createInvitation(
          email: $email
      ){
          id
          email
          token
          updatedAt
      }
  }
`;

class SendInvitation extends Component {
  state = {
    name: '',
    email: '',
    password: ''
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value});
  };

  async onSubmit(e, sendInvitation){
    e.preventDefault();
    await sendInvitation();
    this.props.history.push('/users');
  };

  render() {
    return (
      <PrivatePage permission="ADMIN">
        <Container>
          <Title>Invite user</Title>
          <Mutation mutation={SEND_INVITATION} variables={{email: this.state.email}} refetchQueries={['ALL_INVITATIONS_LIST']}>
            {(sendInvitation, {loading, error}) => (
              <form onSubmit={ev => this.onSubmit(ev, sendInvitation)}>
                <ErrorMessage error={error} />
                <BusyWrapper busy={loading}>
                  <fieldset>
                    <label>
                      Email
                      <input type='text' value={this.state.email} onChange={this.handleChange} name='email' />
                    </label>
                    <input type="submit" value="Send" className="button" />
                  </fieldset>
                </BusyWrapper>
              </form>
            )}
          </Mutation>
          <Link to='/users'>go back</Link>
        </Container>
      </PrivatePage>
    );
  }
}

export default withRouter(SendInvitation);
