import React, { Component } from 'react';
import PageLoader from '../common/PageLoader';

import styled, {ThemeProvider} from 'styled-components';

// language=SCSS
const Container = styled.div`
  h1 {
    font-weight: 600;
  }
  
  h2 {
    font-weight: 600;
  }
  
  .nav-pills {
  .nav-link {
      color: ${props => props.theme.brand1};
    }
  
  .nav-link.active,
  .show > .nav-link {
      color: white;
      background-color: ${props => props.theme.brand1};
    }
  }
  
  .button {
    color: white;
    background-color: ${props => props.theme.brand2};
    &:hover{
      background-color: ${props => props.theme.brand1};
    }
  }
`;

const theme = {
  brand1: "#F63A7A",
  brand2: "#870141"
};

class Page extends Component {

  render() {
    const { children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <PageLoader>
            {children}
          </PageLoader>
        </Container>
      </ThemeProvider>
    );
  }
}

export default Page;
