import React, { Component } from 'react';

import {Route,Switch } from "react-router-dom";


import UserManagement from '../components/views/UserManagement/UserManagement';
import Profile from '../components/views/UserManagement/Profile';
import SendInvitation from '../components/views/UserManagement/SendInvitation';
import AddUser from '../components/views/UserManagement/AddUser';



class UsersIndex extends Component {
  render() {
    return (
      <Switch>
        <Route path="/users/add" component={AddUser} />
        <Route path="/users/invite" component={SendInvitation} />
        <Route path="/users/me" component={Profile} />
        <Route component={UserManagement} />
      </Switch>
    );
  }
}

export default UsersIndex;
