import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link,Switch } from "react-router-dom";

import Login from './login';
import Logout from './logout';
import Register from './register';
import Reset from './reset';
import Remind from './remind';
import Dashboard from './dashboard';
import Apps from './apps';
import Users from './users';
import Docs from './docs';


class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <Route path="/register/:token" component={Register} />
          <Route path="/reset/:token" component={Reset} />
          <Route path="/remind" component={Remind} />
          <Route path="/apps" component={Apps} />
          <Route path="/users" component={Users} />
          <Route path="/docs" component={Docs} />
          <Route component={Dashboard} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
