import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ErrorMessage from '../../common/ErrorMessage';
import { Mutation } from 'react-apollo';
import QueryLoader from '../../common/QueryLoader';
import TopBar from '../../layouts/PrivatePage';
import PublicPage from '../../layouts/PublicPage';
import { withRouter } from 'react-router-dom';

const Form = styled.form`
   max-width: 500px;
   margin: 0 auto;
   .right{
      text-align: right;
      input{
      width: 8em;
      }
   }
`;


const RESET_PASSWORD = gql`
    mutation RESET_PASSWORD($email: String!){
        sendResetPassword(email: $email){
            id
        }
    }
`;

class RegisterForm extends Component {
  state = {
    email: '',
  };
  change = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  submit = async (e, resetPassword) => {
    e.preventDefault();
    try {
      await resetPassword();
    }catch(err){}
    this.props.history.push('/#Reset email has been sent!');
  };
  render() {
    return (
      <Mutation mutation={RESET_PASSWORD} variables={this.state}>
      {(resetPassword, {loading, error}) => (
        <PublicPage>
          <Form method="POST" onSubmit={e => this.submit(e, resetPassword)}>
            <h2>Reset password</h2>
            <ErrorMessage error={error /* && {message: "Invalid username or password!"}*/} />
            <fieldset disabled={loading}>
              <label htmlFor="email">
                Email address
                <input type="email" name='email' value={this.state.email} onChange={this.change} />
              </label>
              <div className='right'>
                <input className="button" type="submit" value='Reset' />
              </div>
            </fieldset>
          </Form>
        </PublicPage>
      )}
      </Mutation>
    );
  }
}

export default withRouter(RegisterForm);
