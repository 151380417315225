import React, {Component} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

const InvitationContainer = styled.div`
  position: relative;
  box-shadow: 1px 1px 3px 3px #e1e1e1;
  background-color: white;
  margin-bottom: 1em;
  .info{
    padding: .5rem;
    .email{
      font-weight: 500;
    }
    .token{
      font-weight: 200;
      font-size: .9em;
    }
    .label{
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
  }
  .options {
    background-color: #1779ba;
    display: flex;
    flex-direction: row;
    .button {
      flex: 1 1 100%;
      margin-bottom: 0;
    }
  }
`;

const CANCEL_INVITATION = gql`
  mutation CANCEL_INVITATION($id: ID!){
      cancelInvitation(id: $id){
          id
      }
  }
`;

const RESEND_INVITATION = gql`
    mutation RESEND_INVITATION($id: ID!){
        resendInvitation(id:$id){
            id
        }
    }
`;

export class Invitation extends Component {
  render() {
    console.log(this.props);
    const {invitation: {id, email, token, updatedAt}} = this.props;
    return (
      <InvitationContainer>
        <div className="info">
          <div className='email'>{email}</div>
          <div className='token'>Registration token: {token}</div>
          <div className='label info'>INVITATION</div>
        </div>
        <div className="options">
          <Mutation mutation={RESEND_INVITATION} variables={{id}} refetchQueries={['ALL_INVITATIONS_LIST']}>
            {(resend, {loading, error}) => (
              <button disabled={loading} className="button" onClick={resend}>{error || 'Resend'}</button>
            )}
          </Mutation>
          <Mutation mutation={CANCEL_INVITATION} variables={{id}} refetchQueries={['ALL_INVITATIONS_LIST']}>
            {(cancel, {loading, error}) => (
              <button disabled={loading} className="button" onClick={cancel}>{error || 'Cancel'}</button>
            )}
          </Mutation>
        </div>
      </InvitationContainer>
    );
  }
}

export default Invitation;
