import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import React, {Component} from 'react';
import LoginForm from '../views/Login/LoginForm';
import PageLoader from './PageLoader';

import type { IUser } from '../views/UserManagement/details/UserList';

export const LOGIN = gql`
    mutation LOGIN($email: String!, $password: String!){
        signin(email: $email, password: $password){
            access_token
            refresh_token
        }
    }
`;

export const GET_ME = gql`
    query GET_ME {
        me @client{
            id
            name
            email
            role
        }
    }
`;

export interface GetMeResult {
  data: {
    me: IUser;
  };
  loading: Boolean;
  error: Error;
}


export const LoginMutation = ({children,variables, ...props}) => (
  <Mutation mutation={LOGIN}
    variables={variables}
    refetchQueries={['GET_ME']}>
    {children}
  </Mutation>
);

const UserContext = React.createContext(null);

export const UserConsumer = UserContext.Consumer;

export class Protected extends Component{
  render(){
    const {children} = this.props;
    return (
      <Query query={GET_ME} >
        {({data, loading, error}: GetMeResult) => {
          if(loading) return <PageLoader />;
          if(error) return <LoginForm />;
          if(!data || !data.me) return <LoginForm/>;
          return (
            <UserContext.Provider value={data.me}>
              {children}
            </UserContext.Provider>
          );
        }}
      </Query>
    );
  }
}
