import React, { Component } from 'react';
import QueryLoader from '../../../common/QueryLoader';
import PrivatePage from '../../../layouts/PrivatePage';
import styled from 'styled-components';
import gql from 'graphql-tag';
import Accordion from './Accordion';
import ReactMarkdown from 'react-markdown';
import { REST_ENDPOINT } from '../../../../config';

const GET_DOCS = gql`
  query {
      docs {
          spec
      }
  }
`;

const Container = styled.div`
  {
    overflow: hidden !important;
    height: 100%;
    width: 100%;
  }

  
`;

const EndpointsContainer = styled.div`
  &>*{
    margin-bottom: 1.5em;
  }
`;
const DescriptionContainer = styled.div`
  
  &>* {
    margin: 1em; 
  }
  
  .argName{
    width: 20%;
  }

  .argType{
    width: 20%;
  }  
  .description{
    border-left: 3px solid lightgray;
    font-style: italic;
    padding-left: 1em;
  }
  .return{
    font-size: .95em;
    h4{
      font-size: 1em;
      font-weight: bold;
      margin-bottom: .3em;
    }
  }
`;


const Title = styled.div`
  & {
    display: flex;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,0.2);
  }
  &>*{
    padding: .3em 1em;
  }
  .path {
    flex: 1 0 50%;
    background-color: ghostwhite;
    color: dimgray;
  }
  .name{
    flex: 1 0 33%;
    font-weight: bold;
    background-color: ghostwhite;
    color: dimgray;
    
  }
  
  .args{
   h5 {
    font-weight: bold;
   }
  }
  .method{
    flex: 1 1 5em;
    border-left: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
    text-align: center;
    background-color: ghostwhite;
    color: dimgray;
  }
  
`;


class TypeSpec extends Component {
  render(){
    const {spec, typeName} = this.props;
    const type = spec.types.find(t=>t.name ===typeName);
    return (
      <DescriptionContainer>
        <h5 id={typeName}>{typeName}</h5>
        <div className="description">{type.description}</div>
        <table>
          <tbody>
            {type.fields && type.fields.map(f=>(
              <tr key={f.name}>
                <td className="argName">{f.name}</td>
                <td className="argType"><a href={'#'+f.type.name}>{f.type.name}</a></td>
                <td className="description">{f.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </DescriptionContainer>
    );
  }
}

class TypeSpecAccordion extends Component {
  render(){
    const {spec, typeName} = this.props;
    return (
      <Accordion className="endpoint" TitleComponent={()=>(
        <Title>
          <div className='name'>{typeName}</div>
        </Title>
      )}>
          <TypeSpec spec={spec} typeName={typeName} />
      </Accordion>
    );
  }
}


class RestDoc extends Component {
  render() {
    return (
      <Container>
        <PrivatePage>
          <QueryLoader query={GET_DOCS}>
            {({docs}) => !docs ? <div/> : <>
              <EndpointsContainer className="grid-container">
                <h3>REST API - documentation</h3>
                <ReactMarkdown>
                  For REST api there is no client yet available.
                  You can use this api with use of any http client you like.
                  For authorization for each endpoint you need to apply query parameter `apiKey` with your app key generated in "Apps" page.
                </ReactMarkdown>
                <h3>Endpoints</h3>
                  {docs.spec.entry.filter(e=>e.path && e.method).map((e, idx)=>(
                    <Accordion className="endpoint" TitleComponent={()=>(
                      <Title>
                        <div className='name'>{e.name}</div>
                        <div className='method'>{e.method}</div>
                        <div className="path">{REST_ENDPOINT}{e.path}</div>
                      </Title>
                    )} key={idx}>
                      <DescriptionContainer>
                        <div className="description">{e.description}</div>
                        <div className="input">
                          <h4>Input</h4>
                          {e.args && e.args.map(a => (
                            <div className="args" key={e.name + '_' + a.name}>
                              <h5>{a.name}</h5>
                              <div className="description">{a.description}</div>
                              <div className="type">{a.type.extensions.map(ex=>{
                                switch(ex){
                                  case 'NOT_NULL': return '**required**';
                                  default: return ex;
                                }
                              }).join(' ')}</div>
                              <TypeSpec spec={docs.spec} typeName={a.type.name} />
                            </div>
                          ))}
                        </div>
                        <div className="return">
                          <h4>Successful response</h4>
                          {e.type.name}
                        </div>
                      </DescriptionContainer>
                    </Accordion>
                  ))}
                <h3>Types</h3>
                {docs.spec.types.map(type => (
                  <TypeSpecAccordion spec={docs.spec} typeName={type.name} key={type.name}/>
                ))}
              </EndpointsContainer>
            </>}
          </QueryLoader>
        </PrivatePage>
      </Container>
    );
  }
}

export default RestDoc;
