import React from 'react';
import { Query } from 'react-apollo';
import ErrorMessage from './ErrorMessage';

export const QueryLoader = ({query, variables, suppressError=false, children}) => (
  <Query query={query} variables={variables}>
    {({loading, error, data})=>(
      <>
        {!suppressError && <ErrorMessage error={error} />}
        {children({...data}, loading, error)}
      </>
    )}
  </Query>
);

export default QueryLoader;
