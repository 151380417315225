import React from 'react';
import gql from 'graphql-tag';
import App from './App';
import QueryLoader from '../../../common/QueryLoader';
import type { IApp } from '../../../types/App';
import { List } from '../../../styles/ListStyles';
import { UserConsumer } from '../../../common/Auth';
import type { IUser } from '../../UserManagement/details/UserList';


export interface IAppListResponse {
  apps: IApp[]
}

export const ALL_APPS_LIST = gql`
    query ALL_APPS_LIST {
        myApps {
            id
            name
            owner {
                id
                name
                email
            }
            token
        }
    }
`;

export const AppList = () => (
  <UserConsumer>
      {(me: IUser) => (
          <QueryLoader query={ALL_APPS_LIST}>
            {({myApps}): IAppListResponse => (
              <List>
                {myApps && myApps.map(app => <App key={app.id} user={app} />)}
              </List>
            )}
          </QueryLoader>
      )}
  </UserConsumer>
);

export default AppList;
