import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
`;

export const Title = styled.h3`
  flex: 1 0;
  color: #3f3f3f;
  margin:0; 
`;

export const Controls = styled.div`
  flex: 0 1 20em;
  text-align: right;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: .5rem;
`;
