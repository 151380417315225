import React from 'react';
import AppsList from './details/AppList';
import { Container, Controls, Header, Title } from '../../styles/ListStyles';
import PrivatePage from '../../layouts/PrivatePage';
import { Link } from 'react-router-dom';


const AppManagement = () => (
  <PrivatePage permission="ADMIN">
    <Container>
      <Header>
        <Title>Apps</Title>
        <Controls>
          <Link to='/apps/add'>
            <button className='button'>Add</button>
          </Link>
        </Controls>
      </Header>

      <AppsList />
    </Container>
  </PrivatePage>
);

export default AppManagement;
