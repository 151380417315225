import React, { Component } from 'react';
import * as d3 from 'd3';
import styled, { ThemeConsumer } from 'styled-components';
import { UserConsumer } from '../../../common/Auth';

const Container = styled.div`
  {
    position: relative;
    text-align: center;
    &:after{
      position:absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: ' ';
      box-shadow: inset 0 0 30px white;
      width:100%;
      height: 100%;
    }
  }
  .info{
    text-align: center;
    color: dimgray;
  }
`;



class LineChart extends Component {
  width = 1000;
  height = 300;
  margin = ({top: 10, right: 0, bottom: 30, left: 0});
  xScale = d3.scaleLinear();
  yScale = d3.scaleLinear();
  xAxisCall = d3.axisBottom();
  yAxisCall = d3.axisLeft();
  transition = d3.transition();
  transitionDuration = 3000;
  line = d3.line()
    .x((d, i) => this.xScale(i))
    .y((d) => this.yScale(d))
    .curve(d3.curveMonotoneX);

  line0 = d3.line()
    .x((d, i) => this.xScale(i))
    .y((d) => this.yScale(0.5))
    .curve(d3.curveMonotoneX);

  data = [0, ...Array(24)
    .fill(10)
    .map(c => c * Math.random()-5), 0];
  interval=null;

  componentWillUnmount(): void {
    if(this.interval){
      clearInterval(this.interval);
    }
    document.removeEventListener('mousemove', this.randomize);
  }

  setup = element => {
    if(this.interval){
      clearInterval(this.interval);
    }
    this.chart = d3.select(element)
      .select('g');
    this.setScale(this.data);
    this.chart.select(".line")
      .datum(this.data)
      .attr("d", this.line0);
    this.updateChart(this.data);
    setInterval(this.randomize, 5000);
  };

  randomize = () => {
    this.data = [0, ...Array(24)
      .fill(10)
      .map(c => c * Math.random()-5), 0];
    this.chart.selectAll("*").interrupt();

    this.setScale(this.data);
    this.updateChart(this.data);
  };
  updateChart (data){
    this.chart.select(".line")
      .datum(data)
      .transition(this.transition)
      .duration(this.transitionDuration)
      .attr("d", this.line);

    const us = this.chart
      .selectAll("circle")
      .data(data);

    us.enter()
      .append("circle")
      .attr("cx", (d, i) => this.xScale(i))
      .attr("cy", () => this.yScale(0.5))
      .style('display', (d,i)=>i===0 || i===(this.data.length-1) ? 'none': 'inherit')
      .transition(this.transition)
      .duration(this.transitionDuration)
      .attr("cy", d => this.yScale(d))
      .attr("r", 5)
      .attr('fill', 'none')
      .attr('stroke', '#ffab00');

    us
      .transition(this.transition)
      .duration(this.transitionDuration)
      .attr("cy", d => this.yScale(d));

    us.exit()
      .transition(this.transition)
      .duration(this.transitionDuration)
      .attr("r", 60)
      .attr('stroke', '#ff1100')
      .remove();
  }

  setScale(data){
    const {margin, height, width} = this;
    this.xScale.domain([0, data.length-1]);
    this.xScale.range([0, width-(margin.right+margin.left)]);

    const rgy = d3.extent(data).reduce((a,b) => b-a)*0.1;
    this.yScale.domain(
      [
        d3.min(data)-rgy,
        d3.max(data)+rgy
      ]
    ).nice();
    this.yScale.range([height - (margin.bottom+margin.top),margin.bottom]);
    this.xAxisCall.scale(this.xScale);
    this.yAxisCall.scale(this.yScale);
  }


  render() {
    const {margin, height, width} = this;
    return (
      <ThemeConsumer>
        {(theme: any) => (
          <Container>
            <svg ref={this.setup} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
              <defs>
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"white",stopOpacity:"0"}} />
                    <stop offset="100%" style={{stopColor:"white",stopOpacity:"1"}} />
                  </linearGradient>
                  <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"white",stopOpacity:"1"}} />
                    <stop offset="100%" style={{stopColor:"white",stopOpacity:"0"}} />
                  </linearGradient>
                </defs>
              </defs>
              <g width={width - (margin.left + margin.right)}
                 height={height - (margin.top + margin.bottom)}
                 transform={"translate(" + margin.left + "," + margin.top + ")"}>
                <path className="line" stroke={theme.brand1} strokeWidth="2" fill="none" />
                <rect x="0" y="0" width="20%" height="100%" style={{fill: "url(#grad2)"}}/>
                <rect x="80%" y="0" width="20%" height="100%" style={{fill: "url(#grad1)"}}/>
              </g>
            </svg>
            <div className="info">Welcome to API dashboard!</div>
            <div className="info">All statistics will be placed here.</div>
          </Container>
          )}
      </ThemeConsumer>
    );
  }
}

export default LineChart;
