import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ErrorMessage from '../../common/ErrorMessage';
import { Mutation } from 'react-apollo';
import SuccessMessage from '../../common/SuccessMessage';
import PublicPage from '../../layouts/PublicPage';
import { Link, withRouter } from 'react-router-dom'
import {LoginMutation} from '../../common/Auth'

const Form = styled.form`
   max-width: 500px;
   margin: 0 auto;
   .controls {
      display: flex;
      flex-direction: row;
   }
   .right{
      flex: 1 1 50%;
      text-align: right;
      input{
      width: 8em;
      }
   }
   .left{
      flex: 1 1 50%;
      text-align: left;
      input{
      width: 8em;
      }
   }
`;

const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!){
      signin(email: $email, password: $password){
          access_token
          refresh_token
      }
  }
`;

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
  };
  change = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  submit = async (e, login) => {
    const { match, location, history } = this.props;
    e.preventDefault();
    await login();
    console.log(this.props);
    if(this.props.redirect){
      history.replace(this.props.redirect);
    }
  };
  render() {
    return (
      <LoginMutation variables={this.state}>
        {(login, {loading, error}) => (
          <PublicPage>
            <Form method="POST" onSubmit={e=>this.submit(e, login)}>
              <h2>Sign in</h2>
              <SuccessMessage message={decodeURI(this.props.location.hash.replace('#',''))}/>
              <ErrorMessage error={error /* && {message: "Invalid username or password!"}*/}/>
              <fieldset disabled={loading}>
                <label htmlFor="email">
                  Email
                  <input type="text" name='email' value={this.state.email} onChange={this.change} />
                </label>
                <label htmlFor="password">
                  Password
                  <input type="password" name='password' value={this.state.password} onChange={this.change} />
                </label>
                <div className="controls">
                  <div className='left'>
                    <Link to="/remind">Forgot your password?</Link>
                  </div>
                  <div className='right'>
                    <input className="button" type="submit" value='login' />
                  </div>
                </div>
              </fieldset>
            </Form>
          </PublicPage>
        )}
      </LoginMutation>
    );
  }
}

export default withRouter(LoginForm);
