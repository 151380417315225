import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faBell,
  faSignOutAlt,
  faCalendarAlt,
  faTrashAlt,
  faSearch,
  faPen,
  faSyncAlt,
  faSpinner,
  faPlus,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faTwitter, faTwitch, faFacebook } from '@fortawesome/free-brands-svg-icons';

library.add(faUser);
library.add(faBell);
library.add(faSignOutAlt);
library.add(faCalendarAlt);
library.add(faTrashAlt);
library.add(faSearch);
library.add(faPen);
library.add(faSyncAlt);
library.add(faSpinner);
library.add(faPlus);
library.add(faCaretDown);

library.add(faYoutube, faTwitter, faTwitch, faFacebook);
