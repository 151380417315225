import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// language=SCSS
const Container = styled.div`
  $size: 40px;
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  
  
  .wrapper {
  
    &.busy {
      opacity: 0.66;
      pointer-events: none;
      position: relative;
    }
  
    .indicator {
      
      display: block;
      position: absolute;
      width: $size;
      height: $size;
      text-align: center;
      line-height: $size;
      font-size: $size;
      left: 50%;
      top: 50%;
      translate: -50%;
      z-index: 1000;
      animation: rotation 1s infinite steps(8, end);
    }
  }
`;



class BusyWrapper extends Component {

  static propTypes = {
    busy: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const {busy, children, className, ...rest} = this.props;
    return (
      <Container {...rest} >
        {busy && (
          <span className="indicator">
            <FontAwesomeIcon icon="spinner" />
          </span>
        )}
        {children}
      </Container>
    );
  }
}

export default BusyWrapper;
