import React from 'react';
import PrivatePage from '../components/layouts/PrivatePage';
import LineChart from '../components/views/Dashboard/LineChart/LineChart';

const Dashboard = () => (
  <PrivatePage>
    <LineChart/>
  </PrivatePage>
);

export default Dashboard;
