import styled from 'styled-components';
import React, {Component} from 'react';

const Loader = styled.div`  
  .container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.brand1};
    background: linear-gradient(45deg, ${props => props.theme.brand2} 40%, ${props => props.theme.brand1} 100%);
    opacity: ${props=>props.show ? 1 : 0};
    transition: all ${props=>props.show ? 0 : 1}s;
    visibility: ${props=>props.show ? 'visible' : 'hidden'};
  }
  //variables

  /* spinner style */
  .spinner {
    position: relative;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: ${props=>props.size}px;
    height: ${props=>props.size}px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-width: 4px;
      border-style: solid;
      border-radius: 50%;
    }
  }

  /* spinner-1 styles */
  .spinner.spinner-1 {

    @keyframes rotate-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes anti-rotate-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    &:before {
      width: ${props=>props.size - props.width * 2}px;
      height: ${props=>props.size - props.width * 2}px;
      border-bottom-color: ${props=>props.color};
      border-right-color: ${props=>props.color};
      border-top-color: ${props=>props.hideColor};
      border-left-color: ${props=>props.hideColor};
      top: 0px;
      left: 0px;
      animation: rotate-animation 1s linear 0s infinite;
    }

    &:after {
      width: ${props=>(props.size - props.width * 2) * 0.7}px;
      height: ${props=>(props.size - props.width * 2) * 0.7}px;
      border-bottom-color: ${props=>props.color};
      border-right-color: ${props=>props.color};
      border-top-color: ${props=>props.hideColor};
      border-left-color: ${props=>props.hideColor};
      top: ${props=>(props.size - props.width * 2) * 0.15}px;
      left: ${props=>(props.size - props.width * 2) * 0.15}px;
      animation: anti-rotate-animation 0.85s linear 0s infinite;
    }
  }

`;

const LoadingContext = React.createContext({
  loadings: 0,
  on: () => null,
  off: () => null
});

class LoadingEnabled extends Component{
  componentDidMount = () => this.props.on();
  componentWillUnmount = () => this.props.off();
  render = () => <div/>;
}

export const Loading = () => (
  <LoadingContext.Consumer>
    {value => <LoadingEnabled {...value} />}
  </LoadingContext.Consumer>
);

export default class PageLoader extends Component {
  componentDidMount(){
    this.off();
  }
  on = () => {
    this.setState({loadings: this.state.loadings+1})
  };
  off = () => {
    this.setState({loadings: this.state.loadings-1})
  };
  state = {
    loadings: 1,
    on: this.on,
    off: this.off
  };
  render() {
    const {children} = this.props;
    return (
      <LoadingContext.Provider value={this.state}>
        {children}
        <Loader color='#fff' hideColor='rgba(0,0,0,0)' size={200} width={4} show={this.state.loadings > 0}>
          <div className="container">
            <div className="spinner spinner-1" />
          </div>
        </Loader>
      </LoadingContext.Provider>
    );
  }
}
