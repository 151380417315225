import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { Playground, store } from 'graphql-playground-react'
import createClient from '../../../../utils/createClient';
import PrivatePage from '../../../layouts/PrivatePage';
import styled from 'styled-components';


const Container = styled.div`
  {
    overflow: hidden !important;
    height: 100%;
    width: 100%;
  }
  .top-bar{
    margin-bottom: 0 !important;
  }
  
`;


class GqlPlayground extends Component {
  settings = {
    'editor.theme': 'light'
  };
  config = {
    projects: {
      app: {
        schemaPath: '/graphql/schema.graphql',
        extensions: {
          endpoints: {
            default: process.env.API_ENDPOINT,
          },
        },
      }
    }
  }
  render() {
    return (
      <Container>
        <Provider store={store}>
          <PrivatePage>
            <Playground
                endpoint={process.env.API_ENDPOINT}
                createApolloLink={createClient}
                settings={this.settings} />
          </PrivatePage>
        </Provider>
      </Container>
    );
  }
}

export default GqlPlayground;
