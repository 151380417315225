import styled from 'styled-components';
import React from 'react';
import { Mutation } from 'react-apollo';
import BusyWrapper from './BusyWrapper';
import ErrorMessage from './ErrorMessage';

export const MutationLoader = ({mutation, variables, refetchQueries, children}) => (
  <Mutation mutation={mutation} variables={variables} refetchQueries={refetchQueries}>
    {(exec, {loading, error}) => (
      <>
        <ErrorMessage error={error} />
        <BusyWrapper busy={loading} >
          {children(exec)}
        </BusyWrapper>
      </>
    )}
  </Mutation>
);

export default MutationLoader;
