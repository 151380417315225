import React, {Component} from 'react';
import styled from 'styled-components';

const UserContainer = styled.div`
  position: relative;
  box-shadow: 1px 1px 3px 3px #e1e1e1;
  background-color: white;
  .info{
    padding: .5rem;
    .name{
      font-weight: 500;
    }
    .owner{
      font-weight: 200;
      font-size: .9em;
    }
    .key{
      font-weight: 200;
      font-size: .9em;
    }
    .label{
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
  }
  .options {
    background-color: #1779ba;
    display: flex;
    flex-direction: row;
    .button {
      flex: 1 1 100%;
      margin-bottom: 0;
    }
  }
`;

export class App extends Component {
  render() {
    const {user: {id, name, disabled, token, owner}} = this.props;
    return (
      <UserContainer>
        <div className="info">
          <div className='name'>{name}</div>
          {owner && <div className='owner'>Owner: {owner.name}</div>}
          <div className='key'>Api key: {token}</div>
          {disabled && <div className='label secondary'>disabled</div>}
        </div>
      </UserContainer>
    );
  }
}

export default App;
