import React from 'react';
import UserList from './details/UserList';
import { Container, Controls, Header, Title } from '../../styles/ListStyles';
import PrivatePage from '../../layouts/PrivatePage';
import InvitationList from './details/InvitationList';
import { Link } from 'react-router-dom';


const Index = () => (
  <PrivatePage permission="ADMIN">
    <Container>
      <Header>
        <Title>Users</Title>
        <Controls>
          <Link to='/users/add'>
            <button className='button'>Add</button>
          </Link>
          <Link to='/users/invite'>
            <button className='button'>Invite</button>
          </Link>
        </Controls>
      </Header>
      <InvitationList />
      <UserList />
    </Container>
  </PrivatePage>
);

export default Index;
