import React, { Component } from 'react';
import './static/style.css';
import './static/nprogress.css';
import './utils/fontAwesome';

import Page from './components/layouts/Page';
import { ApolloProvider } from 'react-apollo';
import createClient from './utils/createClient';
import AppRouter from './routes/index';

const apollo = createClient({});

class App extends Component {
  render() {
    return (
      <ApolloProvider client={apollo}>
        <Page>
          <AppRouter />
        </Page>
      </ApolloProvider>
    );
  }
}


export default App;
