import React from 'react';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
  background-color: lightcoral;
  color: white;
  border: 1px solid orangered;
  padding: .5em;
`;

export default ({error}) => (
  error ? (
    <ErrorContainer>
      {error.message}
    </ErrorContainer>
  ) : null
);

