import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ErrorMessage from '../../common/ErrorMessage';
import { Mutation } from 'react-apollo';
import QueryLoader from '../../common/QueryLoader';
import PublicPage from '../../layouts/PublicPage';
import { withRouter } from 'react-router-dom';

const Form = styled.form`
   max-width: 500px;
   margin: 0 auto;
   .right{
      text-align: right;
      input{
      width: 8em;
      }
   }
`;

const GET_INVITATION = gql`
    query GET_INVITATION($token: String!){
        getInvitation(token: $token){
            email
        }
    }
`;

const REGISTER = gql`
    mutation REGISTER($name: String!, $password: String!, $token: String!){
        register(body: {
            name: $name,
            password: $password,
            token: $token
        }){
            id
            name
            email
        }
    }
`;

class RegisterForm extends Component {
  state = {
    name: '',
    password: '',
  };
  change = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  submit = (e, register) => {
    e.preventDefault();
    this.setState({
      token: this.props.query.token
    }, async () => {
      await register();
      this.props.history.push('/#Registration successful! Please login.');
    });
  };
  render() {
    const {invitation} = this.props;
    return (
      <Mutation mutation={REGISTER} variables={this.state}>
        {(register, {loading, error}) => (
          <Form method="POST" onSubmit={e => this.submit(e, register)}>
            <h2>Create edotw user</h2>
            <ErrorMessage error={error} />
            <fieldset disabled={loading}>
              <input type="hidden" name="token" value={this.props.query.token}/>
              <label htmlFor="email">
                Email
                <input type="text" name='email' value={invitation.email} disabled/>
              </label>
              <label htmlFor="name">
                Name
                <input type="text" name='name' value={this.state.name} onChange={this.change} />
              </label>
              <label htmlFor="password">
                Password
                <input type="password" name='password' value={this.state.password} onChange={this.change} />
              </label>
              <div className='right'>
                <input className="button" type="submit" value='Register' />
              </div>
            </fieldset>
          </Form>
        )}
      </Mutation>
    );
  }
}

class RegisterPage extends Component {
  render(){
    return (
      <PublicPage>
        <QueryLoader query={GET_INVITATION} suppressError={true} variables={{token: this.props.match.params.token}}>
          {({getInvitation}, loading, error) => {
            if(error) return (<h3>Invitation does not exist!</h3>);
            if(loading) return null;
            return (<RegisterForm invitation={getInvitation} query={this.props.match.params}/>);
          }}
        </QueryLoader>
      </PublicPage>
    );
  }
}

export default withRouter(RegisterPage);
