import React, { Component } from 'react';
import TopBar from '../common/TopBar';

class PublicPage extends Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <TopBar />
        {
          typeof children === 'function'
            ? children()
            : children
        }
      </>
    );
  }
}

export default PublicPage;
