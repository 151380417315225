import React, { Component } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const AccordionContainer = styled.div`
  .title{
  }
  .body{
    
    display: block;
    
  }
  &.closed{
    .body{
      display: none;
    }
  }
`;

class Accordion extends Component {
  state = {show: false};
  toggle = () => this.setState({show: !this.state.show});
  render() {
    const { TitleComponent, children} = this.props;
    return (
      <AccordionContainer  className={cn({closed: !this.state.show}, this.props.className)}>
        <div className="title" onClick={this.toggle}>
          <TitleComponent />
        </div>
        <div className="body">
          {children}
        </div>
      </AccordionContainer>
    );
  }
}

export default Accordion;
